
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  // baseUrl = "http://192.168.1.50/boilerplate/bhl_hubadmin_api/web/v1"     

  // Staging
  baseUrl = "https://bhlfinanceapi.bigcityvoucher.co.in/v1"

  // Prodduction
  // baseUrl = "https://bhlfinanceapi.bigcityexperience.com/web/v1"
  // login 
  login = this.baseUrl + "/hubadmin/log-in"

  // Bank Mis
  getlistOfMIS = this.baseUrl + "/hubadmin/get-mis-list"
  getMISLineItems = this.baseUrl + "/hubadmin/get-line-item-by-mis-id"
  createMIS = this.baseUrl + "/hubadmin/create-mis"
  truncateMIS = this.baseUrl + "/hubadmin/delete-mis";
  downloadMIS = this.baseUrl + "/hubadmin/download-mis-by-id";
  bulkUtrUpload = this.baseUrl + "/hubadmin/bulk-utr-upload";
  updateBankUtr = this.baseUrl + "/finance/update-utrbyid";
  editBankMis = this.baseUrl + "/hubadmin/edit-mis-by-id";

  // Payout Master
  payoutList = this.baseUrl + "/hubadmin/readpayoutmaster"
  readPayoutdetails = this.baseUrl + "/hubadmin/readpayoutdetail"
  generatePayout = this.baseUrl + "/hubadmin/generatepayout"

  // agent Payout
  agentPayoutList = this.baseUrl + "/finance/agentpayoutlist"
  agentPayoutListByID = this.baseUrl + "/finance/agentpayoutlistbyid"
  updateAgentUtr = this.baseUrl + "/hubadmin/updateutr";
  downloadAgentPayoutFile = this.baseUrl + "/hubadmin/downloadlineitembypoid"
  uploadAgentUTR = this.baseUrl + "/hubadmin/agentutruploadbulk"
  updateagentgst = this.baseUrl + "/hubadmin/updateagentgst"
  paidCase = this.baseUrl + "/finance/get-paid-cases"
  invoiceView = this.baseUrl + '/hubadmin/get-invoice-details'

  // Points
  PointsSummary = this.baseUrl + '/hubadmin/getpointlist'

  // payout recovery
  recoveryPayouts = this.baseUrl + "/finance/recovery-payouts";
  recoveryList = this.baseUrl + "/hubadmin/readrecovery";


}
export interface ApiConfigurationInterface { }